import React from "react";
import PropTypes from "prop-types";
import { graphql, Link as GatsbyLink } from "gatsby";
import SEO from "@components/SEO";
import { Content, Link, View, Image } from "@flive/react-kit";
import HTMLRenderer from "@components/HTMLRenderer";

const { Paragraph, Title } = Content;

const Page = ({ data }) => {
  const {
    title,
    slug,
    content: { html },
    description,
    banner,
  } = data.graphcms.pages[0];
  return (
    <View>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title={title} description={description} pathname={slug} />

      <View position="relative" height="box-14" width="100%" overflow="hidden">
        <View
          position={{ absolute: { top: "0", left: "0" } }}
          width="100%"
          height="box-14"
          backgroundImageSrc={banner.url}
          backgroundRepeat="repeat"
          backgroundSize="cover"
          backgroundPosition="0px 0px"
        ></View>
        <View
          position={{ absolute: { top: "mini-0", left: "mini-0" } }}
          width="100%"
          height="box-14"
        >
          <View
            display="flex"
            flexDirection="column"
            alignItems="center"
            margin={{ top: "mini-8" }}
            textAlign="center"
          >
            <Title textColor="invertContent">{title}</Title>
            <Paragraph textColor="invertContent">{description}</Paragraph>
          </View>
        </View>
      </View>
      <View
        display="flex"
        flexDirection="column"
        justifyContent={{ xs: "center", lg: "flex-start" }}
        padding="mini-3"
        width={{ md: "layout-30", lg: "layout-40" }}
        margin={{ x: "auto" }}
      >
        {html && <HTMLRenderer>{html}</HTMLRenderer>}
      </View>
    </View>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;

export const query = graphql`
  query ($id: ID!) {
    graphcms {
      pages(where: { id: $id }) {
        slug
        homePage
        showContactForm
        title
        description
        slug
        content {
          html
        }
        banner {
          url
        }
      }
    }
  }
`;
