import React from "react";
import htmr from "htmr";
import { Content, Link, View } from "@flive/react-kit";

const { Title, Preformatted, List, ListItem, Paragraph, Strong, Code } =
  Content;

const transform = {
  wrapper: ({ children }) => <>{children}</>,
  inlineCode: (props) => <Code {...props} />,
  pre: Preformatted,
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  h1: (props) => <Title variant="h1" anchor={!!props.id} {...props} />,
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  h2: (props) => <Title variant="h2" anchor={!!props.id} {...props} />,
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  h3: (props) => <Title variant="h3" anchor={!!props.id} {...props} />,
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  h4: (props) => <Title variant="h4" anchor={!!props.id} {...props} />,
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  h5: (props) => <Title variant="h5" anchor={!!props.id} {...props} />,
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  h6: (props) => <Title variant="h6" anchor={!!props.id} {...props} />,
  p: (props) => <Paragraph {...props} />,
  b: (props) => <Strong {...props} />,
  // eslint-disable-next-line react/prop-types
  a: (props) => {
    const computedProps = {};
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    if (props.href && props.href.match(/^https?/)) {
      computedProps.external = true;
    }
    return <Link {...props} {...computedProps} />;
  },
  ul: (props) => <List variant="unordered" {...props} />,
  ol: (props) => <List variant="ordered" {...props} />,
  li: (props) => <ListItem {...props} />,
  table: (props) => (
    <Table {...props} margin={{ top: "mini-2", bottom: "mini-3" }} />
  ),
  tbody: (props) => <Table.Body {...props} />,
  td: (props) => <Table.Cell {...props} />,
  th: (props) => <Table.Cell variant="header" {...props} />,
  thead: (props) => <Table.Header {...props} />,
  tr: (props) => <Table.Row {...props} />,
  div: (props) => <div {...props} />,
};

function HTMLRenderer({ children }) {
  return htmr(children, { transform });
}

export default HTMLRenderer;
